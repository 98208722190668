import React, {
  memo,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle
} from 'react';
import PropTypes from 'prop-types';
import checkProps from '@jam3/react-check-extra-props';
import classnames from 'classnames';
import { gsap } from 'gsap';
import dynamic from 'next/dynamic';

import { useRouter } from 'next/router';
import useCopy from '../../utils/hooks/use-copy';

import useMotion from './Interests.motion';
import styles from './Interests.module.scss';

import pillButtonKeys from '../../keys/pillButton';

import useFeatureStore from '../../store/features';
import useTracking from '@/utils/hooks/use-tracking';

import FeatureCardFront from '@/components/FeatureCardFront/FeatureCardFront';
import TinderCard from '@/components/TinderCard/TinderCard';
import InterestsSwipeButtons from '@/components/InterestsSwipeButtons/InterestsSwipeButtons';

// lazy loaded
const PillButton = dynamic(() => import('@/components/PillButton/PillButton'));

const trackingSelectionKeys = {
  swipe: {
    select: 'WELCOME_SWIPE_SELECT',
    uninterested: 'WELCOME_SWIPE_UNINTERESTED'
  },
  click: {
    select: 'WELCOME_CLICK_SELECT',
    uninterested: 'WELCOME_CLICK_UNINTERESTED'
  }
};

const Interests = forwardRef((props, ref) => {
  const { list } = props;
  const { trackEvent } = useTracking();
  const trackKeys = useRef(trackingSelectionKeys.swipe);

  const containerRef = useRef(null);
  const swipeButtonsRef = useRef();

  const cardsStackMain = useRef(null);
  const cardsStackSide1 = useRef(null);
  const cardsStackSide2 = useRef(null);
  const titleRef = useRef(null);
  const paginationRef = useRef(null);
  const featureSelectRef = useRef(null);
  const ctaRef = useRef(null);
  const featureCardFrontRefs = useRef([]);
  const swipeRef = useRef(null);
  const cardWrapperRef = useRef(null);

  const { animateIn } = useMotion({
    titleRef,
    paginationRef,
    featureSelectRef,
    ctaRef,
    featureCardFrontRefs
  });

  const copy = useCopy();

  const router = useRouter();
  const { setFeature, resetFeatures } = useFeatureStore((state) => ({
    setFeature: state.setFeature,
    resetFeatures: state.resetFeatures
  }));

  const [swipeDirection, setSwipeDirection] = useState(null);
  const [card, setCard] = useState(list.length - 1);

  let currentCardRef = useRef(null);
  currentCardRef.current = card;

  const swipeBlocked = useRef(false);
  const swipeTimeout = useRef(null);

  const goToNextCard = (match = false, groupIndex = 0) => {
    if (match) {
      setFeature({
        categoryName: list[currentCardRef.current].name,
        groupIndex
      });
    }
    setCard(currentCardRef.current - 1);
  };

  const onSwipeClinched = (direction) => {};

  const onSwipeInitiated = () => {
    trackKeys.current = trackingSelectionKeys.swipe;
    swipeBlocked.current = true;
  };

  const onSwipeUpdate = (direction, translateX) => {
    if (translateX < 0) {
      setSwipeDirection('left');
    } else if (translateX > 0) {
      setSwipeDirection('right');
    }
  };

  const onSwipeCameBack = () => {
    setSwipeDirection(null);
    swipeBlocked.current = false;
  };

  const onCardLeftScreen = (direction) => {
    setSwipeDirection(null);
    if (currentCardRef.current === 2) {
      gsap.to(cardsStackSide2.current, {
        autoAlpha: 0,
        duration: 0.5,
        ease: 'expo.inOut'
      });
    } else if (currentCardRef.current === 1) {
      gsap.to(cardsStackSide1.current, {
        autoAlpha: 0,
        duration: 0.5,
        ease: 'expo.inOut'
      });
    }
    if (direction === 'left') {
      //Track uninterested
      trackEvent(trackKeys.current.uninterested, {
        FEATURE: list[currentCardRef.current].name
      });

      goToNextCard(false);
      swipeButtonsRef.current.increaseDiscardCount();
    } else if (direction === 'right') {
      //Track select
      trackEvent(trackKeys.current.select, {
        FEATURE: list[currentCardRef.current].name
      });

      goToNextCard(true, 0); // defaults feature group index to 0
      swipeButtonsRef.current.increaseSelectCount();
    }
  };

  const handleSwipe = (dir) => {
    if (swipeBlocked.current) return;
    blockSwipe();
    swipeRef.current && swipeRef.current.swipe(dir);
    setSwipeDirection(dir);
  };

  const handleSelect = () => {
    swipeBlocked.current = false;
    trackKeys.current = trackingSelectionKeys.click;
    handleSwipe('right');
    swipeBlocked.current = true;
  };
  const handleDiscard = () => {
    swipeBlocked.current = false;
    trackKeys.current = trackingSelectionKeys.click;
    handleSwipe('left');
    swipeBlocked.current = true;
  };

  const blockSwipe = () => {
    swipeBlocked.current = true;
    swipeTimeout.current = setTimeout(() => {
      swipeBlocked.current = false;
      clearTimeout(swipeTimeout.current);
    }, 800);
  };

  const getCardWrapperDimension = () => {
    return cardWrapperRef.current
      ? cardWrapperRef.current.getBoundingClientRect()
      : null;
  };

  useImperativeHandle(ref, () => ({
    animateIn,
    getCardWrapperDimension,
    cardsStack: {
      main: cardsStackMain.current,
      side1: cardsStackSide1.current,
      side2: cardsStackSide2.current
    }
  }));

  useEffect(() => {
    resetFeatures();

    return () => {
      if (swipeTimeout.current) clearTimeout(swipeTimeout.current);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (card < 0) {
      setTimeout(() => {
        trackEvent('WELCOME_COMPLETE');
        router.push('/results');
      }, 0);
    }

    // eslint-disable-next-line
  }, [card]);

  return (
    <main className={styles.Interests} ref={containerRef}>
      <section className={styles.interestsSection}>
        <div>
          <h1 className={classnames(styles.heading)} ref={titleRef}>
            {copy('swipe_interest_QR_headline_1')}
          </h1>
          <div ref={paginationRef} className={styles.pagination}>
            {Math.min(6, 6 - card)} / 6
          </div>
        </div>
        <div ref={cardWrapperRef} className={styles.featureCardsWrapper}>
          <div className={styles.featureCard}>
            <div ref={cardsStackMain} className={styles.cardsStack}>
              <span ref={cardsStackSide1} />
              <span ref={cardsStackSide2} />
            </div>
            {list.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ zIndex: 10 + index }}
                  className={classnames(
                    styles.stackedFeature,
                    index >= 4 ? styles.stackedFeatureOffsetTop : ''
                  )}
                  aria-hidden={currentCardRef.current !== index}
                >
                  <TinderCard
                    ref={index === card ? swipeRef : null}
                    onSwipeClinched={onSwipeClinched}
                    onSwipeInitiated={onSwipeInitiated}
                    onSwipeUpdate={onSwipeUpdate}
                    onSwipeCameBack={onSwipeCameBack}
                    onCardLeftScreen={onCardLeftScreen}
                    className={styles.tinderCard}
                    params={{ getSpeedUpdate: true }}
                    preventSwipe={['up', 'down']}
                  >
                    <FeatureCardFront
                      ref={(ref) => (featureCardFrontRefs.current[index] = ref)}
                      title={copy(item.key)}
                      description={copy(item.body)}
                      imgSrc={item.media}
                    />
                  </TinderCard>
                </div>
              );
            })}
          </div>
          <div ref={featureSelectRef} className={styles.featureSelect}>
            <InterestsSwipeButtons
              ref={swipeButtonsRef}
              handleDiscard={handleDiscard}
              handleSelect={handleSelect}
              swipeDirection={swipeDirection}
            />
          </div>
        </div>
        <div ref={ctaRef}>
          <PillButton
            className={styles.cta}
            label={copy('swipe_interest_QR_cta')}
            type={pillButtonKeys.BUTTON}
            onClick={() => {
              trackEvent('WELCOME_CLICK_MEET_MATCH');
              setCard(-1);
            }}
          />
        </div>
      </section>
    </main>
  );
});

Interests.propTypes = checkProps({
  className: PropTypes.string,
  list: PropTypes.array
});

Interests.defaultProps = {};

export default memo(Interests);
