import React, { memo, forwardRef, useImperativeHandle, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './LandingTitle.module.scss';
import useMotion from './LandingTitle.motion';
import GoogleSvg from '../../assets/svgs/google.svg';

import useCopy from '@/utils/hooks/use-copy';

const LandingTitle = forwardRef((props, ref) => {
  const { className, withMotion } = props;

  const logoRef = useRef(null);
  const titleRef = useRef(null);

  const copy = useCopy();

  const { animateIn, animateOut } = useMotion({ logoRef, titleRef });
  useImperativeHandle(ref, () => ({
    animateIn,
    animateOut
  }));

  const title = copy('intro_welcome_QR_headline_best', true)
    .split('\n')
    .map((line, index) => (
      <span key={`TitleLine-${index}`} className={classnames(styles.titleLine)}>
        {line}
      </span>
    ));

  const veryVoid = () => {};

  return (
    <div className={classnames(styles.LandingTitle, className)}>
      <div
        ref={logoRef}
        className={styles.logoWrapper}
        aria-hidden={true}
        onClick={veryVoid}
      >
        <GoogleSvg className={classnames(styles.googleSvg)} />
      </div>
      <h1
        ref={titleRef}
        className={classnames(styles.title, withMotion ? styles.hidden : '')}
      >
        {title}
      </h1>
    </div>
  );
});

LandingTitle.propTypes = checkProps({
  className: PropTypes.string,
  withMotion: PropTypes.bool
});

LandingTitle.defaultProps = {
  withMotion: false
};

export default memo(LandingTitle);
