import { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { Flip } from '@/vendor/gsap-club/src/Flip';

const Motion = (refs, props) => {
  const { wrapperRef, cardRefs, colorOverlayRefs, backingPlaceholderRef } = refs;

  // const { squareIndices } = props;

  const inTl = useRef(null);
  const outTl = useRef(null);
  const scaleInTl = useRef(null);
  const moveTl = useRef(null);

  // ////////////////////////////
  // Animating in component
  // ////////////////////////////
  const animateIn = (interestsRef) => {
    if (inTl.current) inTl.current.kill();
    inTl.current = gsap.timeline({
      onComplete: () => (inTl.current = null)
    });

    const CARDS_FLYING_DURATION = 1.4;

    inTl.current.fromTo(
      cardRefs.current,
      { y: '150%', opacity: 0 },
      {
        y: 0,
        opacity: 1,
        duration: CARDS_FLYING_DURATION,
        ease: 'power2.inOut',
        stagger: 0.1
      }
    );

    // squareIndices.forEach((i) => {
    //   inTl.current.fromTo(
    //     cardRefs.current[i],
    //     { height: '331rem' },
    //     { height: '190rem', duration: CARDS_FLYING_DURATION, ease: 'expo.inOut', stagger: 0.1 },
    //     0.2
    //   );
    // });

    inTl.current.call(() => scaleInMainCard(), null, CARDS_FLYING_DURATION + 0.5);
    inTl.current.call(() => moveSideCards(), null, CARDS_FLYING_DURATION + 0.7);

    if (interestsRef.current) {
      inTl.current.call(
        () => {
          inTl.current.to(cardRefs.current[5], { opacity: 0 }, '+=0.1');
          Flip.fit(cardRefs.current[1], interestsRef.current.cardsStack.main, {
            duration: 0.7,
            ease: 'power2.inOut'
          });
          Flip.fit(cardRefs.current[2], interestsRef.current.cardsStack.side1, {
            duration: 0.7,
            ease: 'power2.inOut'
          });
          Flip.fit(cardRefs.current[0], interestsRef.current.cardsStack.side2, {
            duration: 0.7,
            ease: 'power2.inOut'
          });
        },
        null,
        CARDS_FLYING_DURATION + 1.6
      );
    }

    return inTl.current;
  };

  // ////////////////////////////
  // Animating out component
  // ////////////////////////////
  const animateOut = () => {
    if (outTl.current) outTl.current.kill();
    outTl.current = gsap.timeline({
      onComplete: () => (outTl.current = null)
    });

    outTl.current.to(wrapperRef.current, {
      opacity: 0,
      duration: 1,
      ease: 'expo.inOut'
    });

    return outTl.current;
  };

  // ////////////////////////////
  // Scaling in main card
  // ////////////////////////////
  const scaleInMainCard = () => {
    if (scaleInTl.current) scaleInTl.current.kill();
    scaleInTl.current = gsap.timeline({
      onComplete: () => (scaleInTl.current = null)
    });

    scaleInTl.current.add(
      Flip.fit(cardRefs.current[1], backingPlaceholderRef.current.main, {
        duration: 0.5,
        ease: 'power2.inOut'
      })
    );

    return scaleInTl.current;
  };

  // ////////////////////////////
  // Moving side cards
  // ////////////////////////////
  const moveSideCards = () => {
    if (moveTl.current) moveTl.current.kill();
    moveTl.current = gsap.timeline({
      onComplete: () => (moveTl.current = null)
    });

    // for mapping
    const stackedCardsIndices = [0, 2, 5];
    const backingCardsIndices = [2, 1, 0];

    backingCardsIndices.forEach((backingCardIndex, index) => {
      moveTl.current.add(
        Flip.fit(cardRefs.current[stackedCardsIndices[index]], backingPlaceholderRef.current.sides[backingCardIndex], {
          duration: 0.7,
          ease: 'power2.inOut'
        }),
        index * 0.1
      );
      moveTl.current.fromTo(
        colorOverlayRefs.current[stackedCardsIndices[index]],
        { opacity: 0 },
        { opacity: 1, duration: 1, ease: 'expo.inOut' },
        index * 0.1
      );
    });

    moveTl.current.to(cardRefs.current[3], { x: '50%', y: '-10%', opacity: 0, duration: 1, ease: 'expo.inOut' }, '-=1');
    moveTl.current.to(cardRefs.current[4], { y: '-50%', duration: 1, opacity: 0, ease: 'expo.inOut' }, '-=1');

    return moveTl.current;
  };

  useEffect(() => {
    return () => {
      inTl.current && inTl.current.kill();
      outTl.current && outTl.current.kill();
      scaleInTl.current && scaleInTl.current.kill();
      moveTl.current && moveTl.current.kill();
    };
  }, []);

  return {
    animateIn,
    animateOut
  };
};

export default Motion;
