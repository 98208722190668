import store from '../../store';

/*

When adding data to FeatureSquareList or FeatureCarousel
The image source property of the object can be either one of the following:

src: [STRING]; //Aka not localized; can be used in every local

OR

src: [OBJECT]; //Localized

Note: it isn't always the `src` property. It's any property that supplies
a media src. For example, the `squareImage` is a property that can be used in
this way as well.

When localizing the data, this `src` property should be
an object with the following values:

{
  default: [STRING OF DEFAULT SRC],
  [local-key]: [STRING OF LOCALIZED MEDIA SRC]
}

for example:

{
  default: 'https://example.com/image1.jpg',
  en_us: 'https://example.com/image1--en_us_override.jpg'
}

Note: When using locale keys as the property, please pull these
from settings/settings.languages.js

*/

const useLocalizedImage = () => {
  const { localeKey: currentLocale } = store.locale((state) => state);

  const getSrc = (src) => {
    const cacheBust = (src) => `${src}?lang=${currentLocale}`;

    if (typeof src === 'string') {
      return cacheBust(src);
    } else if (typeof src === 'object') {
      if (src[currentLocale]) {
        return cacheBust(src[currentLocale]);
      } else {
        return cacheBust(src.default);
      }
    }
  };

  return getSrc;
};

export default useLocalizedImage;
