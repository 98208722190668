import React, { useRef, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './index.module.scss';

import Head from '@/components/Head/Head';
import LandingTitle from '@/components/LandingTitle/LandingTitle';
import Welcome from '@/components/Welcome/Welcome';

import { useAppStore } from '@/store';

function Landing({ className }) {
  const containerRef = useRef();
  const landingTitleRef = useRef();
  const welcomeRef = useRef();

  const { setShowLoader, setShowContent } = useAppStore();

  useEffect(() => {
    setTimeout(() => {
      landingTitleRef.current.animateIn();
      welcomeRef.current.animateIn(landingTitleRef.current.animateOut);
      setShowLoader(true);
      setShowContent(false);
    }, 50);

    // eslint-disable-next-line
  }, []);

  return (
    <main className={classnames(styles.Landing, className)} ref={containerRef}>
      <Head title="intro_welcome_QR_headline_best" />
      {/* {!introAnimationEnded && (
        <p
          className={styles.hiddenCallout}
          aria-label={copy('aria_label_warm_welcome_animation')}
          aria-live="assertive"
        >
          {copy('aria_label_warm_welcome_animation')}
        </p>
      )} */}
      <LandingTitle ref={landingTitleRef} withMotion={true} />
      <Welcome ref={welcomeRef} />
    </main>
  );
}

Landing.propTypes = checkProps({
  className: PropTypes.string
});

Landing.defaultProps = {};

export default memo(Landing);
