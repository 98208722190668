import React, { memo, useRef, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './PlaceholderBackingCards.module.scss';

const PlaceholderBackingCards = forwardRef(({ className }, ref) => {
  const mainCardRef = useRef(null);
  const sideCardRefs = useRef([]);

  const backingCardsOffset = 20;
  const backingCards = Array(3)
    .fill(0)
    .map((item, index) => (
      <div
        key={`BackingCard-${index}`}
        ref={(ref) => (sideCardRefs.current[index] = ref)}
        className={classnames(styles.sideCard)}
        style={{
          width: `calc(100% - ${backingCardsOffset * (index + 1)}px)`,
          top: `-${(backingCardsOffset / 2) * (index + 1)}px`,
          left: `${(backingCardsOffset / 2) * (index + 1)}px`,
          zIndex: 3 - index
        }}
      />
    ));

  useImperativeHandle(ref, () => ({
    main: mainCardRef.current,
    sides: sideCardRefs.current
  }));

  return (
    <div className={classnames(styles.PlaceholderBackingCards, className)}>
      {backingCards}
      <div ref={mainCardRef} className={classnames(styles.mainCard)} />
    </div>
  );
});

PlaceholderBackingCards.propTypes = checkProps({
  className: PropTypes.string
});

PlaceholderBackingCards.defaultProps = {};

export default memo(PlaceholderBackingCards);
