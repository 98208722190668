import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { useAppStore } from '@/store';

const Motion = (refs) => {
  const { welcomeIntroRef, interestsWrapperRef, interestsRef } = refs;

  const { setIntroAnimationEnded } = useAppStore();

  const tl = useRef(null);

  const animateIn = (onWarmWelcomeEnded = () => {}, onEnded = () => {}) => {
    if (tl.current) tl.current.kill();

    tl.current = gsap.timeline({
      onComplete: () => (tl.current = null)
    });

    tl.current
      .add(welcomeIntroRef.current.animateIn(interestsRef), 0)
      .call(() => setIntroAnimationEnded(true), null, '<')
      .add(onWarmWelcomeEnded()) // hide title
      .add(gsap.to(interestsWrapperRef.current, { opacity: 1 }), '-=0.1')
      .add(welcomeIntroRef.current.animateOut()) // hide previous warm welcome animation
      .add(interestsRef.current.animateIn(), '-=3')
      .call(() => onEnded(), null, '-=1.2'); // call the swipe prompt overlay
  };

  useEffect(() => {
    return () => {
      tl.current && tl.current.kill();
    };
  }, []);

  return {
    animateIn
  };
};

export default Motion;
