import React, { memo, forwardRef, useRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import ImagePreloader from '../ImagePreloader/ImagePreloader';
import FeatureCardContainer from '../FeatureCardContainer/FeatureCardContainer';

import useMotion from './FeatureCardFront.motion';
import styles from './FeatureCardFront.module.scss';
import useLocalizedImage from '@/utils/hooks/use-localized-image';

const FeatureCardFront = forwardRef((props, ref) => {
  const { className, title, description, imgSrc } = props;
  const textContainerRef = useRef(null);
  const getSrc = useLocalizedImage();

  const { animateIn } = useMotion({ textContainerRef });

  useImperativeHandle(ref, () => ({
    animateIn
  }));

  return (
    <FeatureCardContainer isIntro={true}>
      <div className={classnames(styles.FeatureCardFront, className)}>
        <div ref={textContainerRef} className={styles.textContainer}>
          <h2 className={classnames(styles.title)}>{title}</h2>
          <p className={classnames(styles.description)}>{description}</p>
        </div>
        <ImagePreloader src={getSrc(imgSrc)} className={styles.image} height={'100%'} fit={'cover'} />
      </div>
    </FeatureCardContainer>
  );
});

FeatureCardFront.propTypes = checkProps({
  className: PropTypes.string,
  title: PropTypes.object,
  description: PropTypes.object,
  imgSrc: PropTypes.string
});

FeatureCardFront.defaultProps = {};

export default memo(FeatureCardFront);
