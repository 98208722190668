import React, { useRef, useImperativeHandle, memo, forwardRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';
import ImagePreloader from '../ImagePreloader/ImagePreloader';
import PlaceholderBackingCards from './PlaceholderBackingCards/PlaceholderBackingCards';

import useMotion from './WelcomeIntro.motion';
import styles from './WelcomeIntro.module.scss';

import useLocalizedImage from '@/utils/hooks/use-localized-image';

const WelcomeIntro = forwardRef(({ className, items }, ref) => {
  const getSrc = useLocalizedImage();
  const wrapperRef = useRef(null);
  const cardsContainerRef = useRef(null);
  const cardRefs = useRef([]);
  const imageRefs = useRef([]);
  const colorOverlayRefs = useRef([]);
  const backingPlaceholderRef = useRef(null);

  const cards = items.map((item, index) => {
    const cardClassNames = classnames(styles.card, index % 2 === 0 ? styles.squareImage : styles.rectangleImage);

    return (
      <div
        key={`WelcomeFeatureCard-${index}`}
        ref={(ref) => (cardRefs.current[index] = ref)}
        className={cardClassNames}
      >
        <ImagePreloader
          ref={(ref) => (imageRefs.current[index] = ref)}
          className={styles.image}
          src={item.featureKey === 'innovation' ? getSrc(item.innovationCentered) : getSrc(item.media)}
          fit="cover"
          width="100%"
          height="100%"
          noFade={true}
        />
        <div ref={(ref) => (colorOverlayRefs.current[index] = ref)} className={classnames(styles.colorOverlay)} />
      </div>
    );
  });

  const squareIndices = [0, 2, 4];
  const { animateIn, animateOut } = useMotion(
    {
      wrapperRef,
      cardsContainerRef,
      cardRefs,
      colorOverlayRefs,
      backingPlaceholderRef
    },
    {
      squareIndices
    }
  );

  useImperativeHandle(ref, () => ({
    animateIn,
    animateOut
  }));

  return (
    <div ref={wrapperRef} className={classnames(styles.WelcomeIntro, className)}>
      <div className={styles.cards}>
        <PlaceholderBackingCards ref={backingPlaceholderRef} />
        <div ref={cardsContainerRef} className={styles.cardsContainer}>
          {cards}
        </div>
      </div>
    </div>
  );
});

WelcomeIntro.propTypes = checkProps({
  className: PropTypes.string,
  items: PropTypes.array
});

WelcomeIntro.defaultProps = {
  items: []
};

export default memo(WelcomeIntro);
