import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const Motion = (refs) => {
  const { textContainerRef } = refs;

  const inTl = useRef(null);

  const animateIn = () => {
    if (inTl.current) inTl.current.kill();
    inTl.current = gsap.timeline({
      onComplete: () => (inTl.current = null)
    });

    inTl.current.fromTo(
      textContainerRef.current,
      { opacity: 0, y: '10%' },
      { opacity: 1, y: 0, duration: 0.5, ease: 'power4.inOut' }
    );

    return inTl.current;
  };

  useEffect(() => {
    return () => {
      inTl.current && inTl.current.kill();
    };
  }, []);

  return {
    animateIn
  };
};

export default Motion;
