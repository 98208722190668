import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

const Motion = (refs) => {
  const {
    titleRef,
    paginationRef,
    featureSelectRef,
    ctaRef,
    featureCardFrontRefs
  } = refs;

  const inTl = useRef(null);

  const animateIn = () => {
    if (inTl.current) inTl.current.kill();
    inTl.current = gsap.timeline({
      onComplete: () => (inTl.current = null)
    });

    inTl.current
      .fromTo(
        [titleRef.current, paginationRef.current],
        { opacity: 0 },
        { opacity: 1, duration: 0.4, ease: 'power4.inOut', stagger: 0.1 }
      )
      .fromTo(
        [featureSelectRef.current, ctaRef.current],
        { opacity: 0, y: '10%' },
        { opacity: 1, y: 0, duration: 0.4, ease: 'power4.inOut', stagger: 0.1 },
        0.1
      )
      .add(
        featureCardFrontRefs.current[
          featureCardFrontRefs.current.length - 1
        ].animateIn(),
        0
      );

    return inTl.current;
  };

  useEffect(() => {
    return () => {
      inTl.current && inTl.current.kill();
    };
  }, []);

  return {
    animateIn
  };
};

export default Motion;
