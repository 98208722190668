import React, { memo, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './InterestsSwipeButtons.module.scss';
import CloseSvg from '../../assets/svgs/close-thin.svg';
import CheckSvg from '../../assets/svgs/checkmark-thin.svg';
import useCopy from '@/utils/hooks/use-copy';

const InterestsSwipeButtons = forwardRef((props, ref) => {
  const { className, handleSelect, handleDiscard, swipeDirection } = props;

  const swipedLeft = swipeDirection === 'left';
  const swipedRight = swipeDirection === 'right';

  const [selectCount, setSelectCount] = useState(0);
  const [discardCount, setDiscardCount] = useState(0);

  const copy = useCopy();
  const discardAriaLabel = copy('aria_label_swipe_discard', true);
  const selectAriaLabel = copy('aria_label_swipe_select', true);

  const increaseSelectCount = () => setSelectCount(selectCount + 1);
  const increaseDiscardCount = () => setDiscardCount(discardCount + 1);

  const onSelectClick = () => {
    handleSelect();
  };

  const onDiscardClick = () => {
    handleDiscard();
  };

  useImperativeHandle(ref, () => ({
    increaseDiscardCount,
    increaseSelectCount
  }));

  return (
    <div className={classnames(styles.InterestsSwipeButtons, className)}>
      <button
        className={classnames(
          styles.button,
          swipedLeft ? styles['selectedButton--discard'] : ''
        )}
        onClick={onDiscardClick}
        aria-label={copy('aria_label_swipe_discard', true)}
      >
        <div
          className={classnames(
            styles.svg,
            styles.svgClose,
            swipedLeft ? styles.invisibleSvg : ''
          )}
        >
          <CloseSvg />
        </div>
        <span
          className={classnames(
            styles.label,
            swipedLeft ? styles.visibleLabel : ''
          )}
          aria-label={discardAriaLabel}
        >
          {discardCount + 1}
        </span>
      </button>
      <button
        className={classnames(
          styles.button,
          swipedRight ? styles['selectedButton--select'] : ''
        )}
        onClick={onSelectClick}
        aria-label={copy('aria_label_swipe_select')}
      >
        <div
          className={classnames(
            styles.svg,
            styles.svgCheckmark,
            swipedRight ? styles.invisibleSvg : ''
          )}
        >
          <CheckSvg />
        </div>
        <span
          className={classnames(
            styles.label,
            swipedRight ? styles.visibleLabel : ''
          )}
          aria-label={selectAriaLabel}
        >
          {selectCount + 1}
        </span>
      </button>
    </div>
  );
});

InterestsSwipeButtons.propTypes = checkProps({
  className: PropTypes.string,
  handleSelect: PropTypes.func,
  handleDiscard: PropTypes.func,
  swipeDirection: PropTypes.string
});

InterestsSwipeButtons.defaultProps = {};

export default memo(InterestsSwipeButtons);
