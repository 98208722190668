import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import checkProps from '@jam3/react-check-extra-props';

import styles from './FeatureCardContainer.module.scss';

function FeatureCardContainer({
  className,
  borderRadius,
  containerRef,
  children,
  isIntro
}) {
  return (
    <div
      ref={containerRef}
      className={classnames(styles.FeatureCardContainer, className)}
      style={
        isIntro
          ? {
              width: 'calc(100vw - 80rem)',
              height: '100%',
              position: 'relative'
            }
          : {}
      }
    >
      <div
        className={classnames(styles.inner, {
          [styles[`borderRadius-${borderRadius}`]]: borderRadius
        })}
      >
        {children}
      </div>
    </div>
  );
}

FeatureCardContainer.propTypes = checkProps({
  className: PropTypes.string,
  containerRef: PropTypes.object,
  borderRadius: PropTypes.oneOf(['sm', 'md']),
  isIntro: PropTypes.bool
});

FeatureCardContainer.defaultProps = {
  isIntro: false
};

export default memo(FeatureCardContainer);
