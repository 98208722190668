/* global WebKitCSSMatrix */
import sleep from 'p-sleep';
import gsap from 'gsap';

export const SETTINGS = {
  snapBackDuration: 300,
  maxTilt: 0.5,
  bouncePower: 0.1,
  swipeThreshold: 300, // px/s
  getSpeedUpdate: false
};

export const getElementSize = (element) => {
  const elementStyles = window.getComputedStyle(element);
  const widthString = elementStyles.getPropertyValue('width');
  const width = Number(widthString.split('px')[0]);
  const heightString = elementStyles.getPropertyValue('height');
  const height = Number(heightString.split('px')[0]);
  return { x: width, y: height };
};

export const pythagoras = (x, y) => {
  return Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2));
};

export const animateOut = async (
  element,
  speed,
  easeIn = false,
  dir = 'right',
  fromButton = false
) => {
  const startPos = getTranslate(element);
  const bodySize = getElementSize(document.body);
  const diagonal = pythagoras(bodySize.x, bodySize.y);

  const velocity = pythagoras(speed.x, speed.y);
  const time = diagonal / velocity;
  const multiplier = diagonal / velocity;

  const translateString = translationString(
    speed.x * multiplier + startPos.x,
    -speed.y * multiplier + startPos.y
  );
  let rotateString = '';

  const rotationPower = 30;

  if (easeIn) {
    element.style.transition = 'ease ' + time + 's';
  } else {
    element.style.transition = 'ease-out ' + time + 's';
  }

  gsap.to(element, { autoAlpha: 0, duration: 0.15, ease: 'expo.inOut' });

  if (getRotation(element) === 0) {
    const rotateSide = dir === 'right' ? 0.5 : -0.5;
    rotateString = rotationString(rotateSide * rotationPower);
  } else if (getRotation(element) > 0) {
    const rotateSide = dir === 'right' ? rotationPower : -rotationPower;
    rotateString = rotationString(rotateSide / 2 + getRotation(element));
  } else {
    const rotateSide = dir === 'right' ? rotationPower : -rotationPower;
    rotateString = rotationString(rotateSide / 2 + getRotation(element));
  }

  fromButton && (await sleep(100));

  element.style.transform = translateString + rotateString;

  await sleep(time * 1000);
};

export const animateBack = (element) => {
  element.style.transition = SETTINGS.snapBackDuration + 'ms';
  const startingPoint = getTranslate(element);
  const translation = translationString(
    startingPoint.x * -SETTINGS.bouncePower,
    startingPoint.y * -SETTINGS.bouncePower
  );
  const rotation = rotationString(getRotation(element) * -SETTINGS.bouncePower);
  element.style.transform = translation + rotation;

  setTimeout(() => {
    element.style = '';
  }, SETTINGS.snapBackDuration * 1.1);
};

export const getSwipeDirection = (speed) => {
  if (Math.abs(speed.x) > Math.abs(speed.y)) {
    return speed.x > 0 ? 'right' : 'left';
  } else {
    return speed.y > 0 ? 'up' : 'down';
  }
};

export const calcSpeed = (oldLocation, newLocation) => {
  const dx = newLocation.x - oldLocation.x;
  const dy = oldLocation.y - newLocation.y;
  const dt = (newLocation.time - oldLocation.time) / 1000;
  return { x: dx / dt, y: dy / dt };
};

export const translationString = (x, y) => {
  const translation = 'translate(' + x + 'px, ' + y + 'px)';
  return translation;
};

export const rotationString = (rot) => {
  const rotation = 'rotate(' + rot + 'deg)';
  return rotation;
};

export const getTranslate = (element) => {
  const style = window.getComputedStyle(element);
  const matrix = new WebKitCSSMatrix(style.webkitTransform);
  const ans = { x: matrix.m41, y: matrix.m42 };
  return ans;
};

export const getRotation = (element) => {
  const style = window.getComputedStyle(element);
  const matrix = new WebKitCSSMatrix(style.webkitTransform);
  const ans = (-Math.asin(matrix.m21) / (2 * Math.PI)) * 360;
  return ans;
};

export const dragableTouchmove = (
  coordinates,
  element,
  offset,
  lastLocation
) => {
  const pos = { x: coordinates.x + offset.x, y: coordinates.y + offset.y };
  const newLocation = { x: pos.x, y: pos.y, time: new Date().getTime() };
  const translation = translationString(pos.x, pos.y);
  const rotCalc = calcSpeed(lastLocation, newLocation).x / 1000;
  const rotation = rotationString(rotCalc * SETTINGS.maxTilt);
  element.style.transform = translation + rotation;
  return newLocation;
};

export const touchCoordinatesFromEvent = (e) => {
  const touchLocation = e.targetTouches[0];
  return { x: touchLocation.clientX, y: touchLocation.clientY };
};

export const mouseCoordinatesFromEvent = (e) => {
  return { x: e.clientX, y: e.clientY };
};
