import { useRef, useEffect } from 'react';
import { gsap } from 'gsap';

import detect from '../../utils/detect';

import { SplitText } from '@/vendor/gsap-club/src/SplitText';

const Motion = (refs) => {
  const splitCopy = useRef(null);
  const inTl = useRef(null);
  const outTl = useRef(null);

  const { logoRef, titleRef } = refs;

  const animateIn = () => {
    if (inTl.current) inTl.current.kill();
    inTl.current = gsap.timeline({
      onComplete: () => (inTl.current = null)
    });

    splitCopy.current = new SplitText(titleRef.current, {
      type: 'lines',
      linesClass: 'SplitText__line'
    }).lines;

    if (detect.device.isiPhone) {
      inTl.current.fromTo(titleRef.current, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.8 }, 0);
    } else {
      inTl.current
        .set(titleRef.current, { autoAlpha: 1 })
        .fromTo(splitCopy.current, { y: '100%' }, { y: 0, duration: 0.8, ease: 'expo.inOut', stagger: 0.3 }, 0)
        .fromTo(
          splitCopy.current,
          { autoAlpha: 0 },
          { autoAlpha: 1, duration: 0.5, ease: 'expo.inOut', stagger: 0.2 },
          0.3
        );
    }

    return inTl.current;
  };

  const animateOut = () => {
    if (outTl.current) outTl.current.kill();
    outTl.current = gsap.timeline({
      onComplete: () => (outTl.current = null)
    });

    outTl.current.to(
      [logoRef.current, splitCopy.current],
      { autoAlpha: 0, duration: 1, ease: 'expo.inOut', stagger: 0.2 },
      0
    );

    return outTl.current;
  };

  useEffect(() => {
    return () => {
      inTl.current && inTl.current.kill();
      outTl.current && outTl.current.kill();
    };
  }, []);

  return { animateIn, animateOut };
};

export default Motion;
