import {
  useLayoutEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
  memo
} from 'react';
import {
  SETTINGS,
  animateOut,
  animateBack,
  getSwipeDirection,
  getTranslate,
  touchCoordinatesFromEvent,
  mouseCoordinatesFromEvent,
  dragableTouchmove,
  calcSpeed
} from './TinderCard.utils';

const TinderCard = forwardRef((props, ref) => {
  const {
    flickOnSwipe = true,
    children,
    onSwipeClinched,
    onSwipeUpdate,
    onSwipeInitiated,
    onSwipeCameBack,
    onCardLeftScreen,
    className,
    preventSwipe = [],
    params = {}
  } = props;

  const swipeAlreadyReleased = useRef(false);
  const element = useRef(null);

  const settings = useMemo(() => Object.assign(SETTINGS, params), [params]);

  useImperativeHandle(ref, () => ({
    swipe: async (dir = 'right') => {
      if (onSwipeClinched) onSwipeClinched(dir);
      const power = 1000;
      const disturbance = (Math.random() - 0.5) * 100;
      if (dir === 'right') {
        await animateOut(
          element.current,
          { x: power, y: disturbance },
          true,
          dir,
          true
        );
      } else if (dir === 'left') {
        await animateOut(
          element.current,
          { x: -power, y: disturbance },
          true,
          dir,
          true
        );
      } else if (dir === 'up') {
        await animateOut(
          element.current,
          { x: disturbance, y: power },
          true,
          dir,
          true
        );
      } else if (dir === 'down') {
        await animateOut(
          element.current,
          { x: disturbance, y: -power },
          true,
          dir,
          true
        );
      }
      element.current.style.display = 'none';
      if (onCardLeftScreen) onCardLeftScreen(dir);
    }
  }));

  const handleSwipeReleased = useCallback(
    async (element, speed) => {
      if (swipeAlreadyReleased.current) {
        return;
      }
      swipeAlreadyReleased.current = true;

      const dir = getSwipeDirection(speed);

      // Check if this is a swipe
      if (
        (dir === 'left' || dir === 'right') &&
        (Math.abs(speed.x) > settings.swipeThreshold ||
          Math.abs(speed.y) > settings.swipeThreshold)
      ) {
        if (onSwipeClinched) onSwipeClinched(dir);

        if (flickOnSwipe) {
          if (preventSwipe && !preventSwipe.includes(dir)) {
            await animateOut(element, speed, false, dir);
            element.style.display = 'none';
            if (onCardLeftScreen) onCardLeftScreen(dir);
          }
        }
      } else {
        // Card was not flicked away, animate back to start
        if (onSwipeCameBack) onSwipeCameBack();
        animateBack(element);
      }
    },
    [
      swipeAlreadyReleased,
      flickOnSwipe,
      onSwipeClinched,
      onSwipeCameBack,
      onCardLeftScreen,
      preventSwipe,
      settings
    ]
  );

  const handleSwipeStart = useCallback(() => {
    if (onSwipeInitiated) onSwipeInitiated();
    swipeAlreadyReleased.current = false;
  }, [swipeAlreadyReleased, onSwipeInitiated]);

  const handleSwipeUpdate = useCallback(
    async (element, speed) => {
      const dir = getSwipeDirection(speed);
      const translateX = getTranslate(element).x;
      if (onSwipeUpdate) onSwipeUpdate(dir, translateX);
      swipeAlreadyReleased.current = false;
    },
    [swipeAlreadyReleased, onSwipeUpdate]
  );

  useLayoutEffect(() => {
    let offset = { x: null, y: null };
    let speed = { x: 0, y: 0 };
    let lastLocation = { x: 0, y: 0, time: new Date().getTime() };
    let mouseIsClicked = false;

    element.current.addEventListener('touchstart', (ev) => {
      if (!settings.passive) {
        ev.preventDefault();
      }
      handleSwipeStart();
      offset = {
        x: -touchCoordinatesFromEvent(ev).x,
        y: -touchCoordinatesFromEvent(ev).y
      };
    });

    element.current.addEventListener('mousedown', (ev) => {
      ev.preventDefault();
      mouseIsClicked = true;
      handleSwipeStart();
      offset = {
        x: -mouseCoordinatesFromEvent(ev).x,
        y: -mouseCoordinatesFromEvent(ev).y
      };
    });

    element.current.addEventListener('touchmove', (ev) => {
      // ev.preventDefault();
      const newLocation = dragableTouchmove(
        touchCoordinatesFromEvent(ev),
        element.current,
        offset,
        lastLocation
      );
      speed = calcSpeed(lastLocation, newLocation);
      lastLocation = newLocation;
      if (settings.getSpeedUpdate) {
        handleSwipeUpdate(element.current, speed);
      }
    });

    element.current.addEventListener('mousemove', (ev) => {
      ev.preventDefault();
      if (mouseIsClicked) {
        const newLocation = dragableTouchmove(
          mouseCoordinatesFromEvent(ev),
          element.current,
          offset,
          lastLocation
        );
        speed = calcSpeed(lastLocation, newLocation);
        lastLocation = newLocation;
        if (settings.getSpeedUpdate) {
          handleSwipeUpdate(element.current, speed);
        }
      }
    });

    element.current.addEventListener('touchend', (ev) => {
      // ev.preventDefault();
      handleSwipeReleased(element.current, speed);
    });

    element.current.addEventListener('mouseup', (ev) => {
      if (mouseIsClicked) {
        ev.preventDefault();
        mouseIsClicked = false;
        handleSwipeReleased(element.current, speed);
      }
    });

    element.current.addEventListener('mouseleave', (ev) => {
      if (mouseIsClicked) {
        ev.preventDefault();
        mouseIsClicked = false;
        handleSwipeReleased(element.current, speed);
      }
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div ref={element} className={className}>
      {children}
    </div>
  );
});

export default memo(TinderCard);
