import React, { memo, forwardRef, useRef, useImperativeHandle } from 'react';
import dynamic from 'next/dynamic';

import useMotion from './Welcome.motion';
import styles from './Welcome.module.scss';

import interests, { INTERESTS_IMAGES } from '@/settings/interests';
import Interests from '@/components/Interests/Interests';
import WelcomeIntro from '@/components/WelcomeIntro/WelcomeIntro';
import { useAppStore } from '@/store';

// lazy loaded
const SwipePromptAnimation = dynamic(() =>
  import('@/components/SwipePromptAnimation/SwipePromptAnimation')
);

const list = Object.entries(interests)
  .reverse()
  .map(([key, value]) => {
    return value.name === 'default-os'
      ? false
      : {
          ...value,
          featureKey: key,
          media: INTERESTS_IMAGES[value.name],
          innovationCentered:
            key === 'innovation' ? INTERESTS_IMAGES.innovationCentered : null
        };
  })
  .filter((e) => e);

const Welcome = forwardRef((props, ref) => {
  const welcomeIntroRef = useRef(null);
  const interestsWrapperRef = useRef(null);
  const interestsRef = useRef(null);

  const {
    swipePromptAlreadyShown,
    setSwipePromptAlreadyShown,
    setShowSwipePrompt,
    prevRoute
  } = useAppStore();

  function onEnded() {
    if (!swipePromptAlreadyShown) {
      setSwipePromptAlreadyShown(true);
      setShowSwipePrompt(true);
    }
  }

  const { animateIn } = useMotion({
    welcomeIntroRef,
    interestsWrapperRef,
    interestsRef
  });

  useImperativeHandle(ref, () => ({
    animateIn: (onWarmWelcomeEnded = () => {}) =>
      animateIn(onWarmWelcomeEnded, onEnded)
  }));

  // [notes / william / 2021-10-01]
  // `photography` should be the first item in the spliced array
  // so that it can appear as the main card in `Warm Welcome`
  // animation.
  const sortedItems = Object.keys(list).map((key) => list[key]);
  sortedItems.splice(1, 0, sortedItems.pop());

  return (
    <div className={styles.Welcome}>
      <WelcomeIntro ref={welcomeIntroRef} items={sortedItems} />
      <div
        className={styles.interestsWrapper}
        ref={interestsWrapperRef}
        style={{ opacity: 0 }}
      >
        <Interests ref={interestsRef} list={list} />
      </div>
      {/* Hide swipe prompt if been on results flow already */}
      {prevRoute === '' && (
        <SwipePromptAnimation
          cardWrapperDimension={
            interestsRef.current
              ? interestsRef.current.getCardWrapperDimension()
              : null
          }
        />
      )}
    </div>
  );
});

Welcome.defaultProps = {
  showOverlay: false
};

export default memo(Welcome);
